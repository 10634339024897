import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Jobs from "../components/jobs";
import cms from "../components/cms/cms"

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`${cms.keyword} jobs`, `${cms.keyword} developer jobs`, `remote ${cms.keyword} jobs`, `${cms.keyword} software engineering jobs`]}
        title={cms.description}
        description={`Looking for a ${cms.keyword} Developer job? ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs.`}
      />
      <Jobs />
    </Layout>
  );
}

export default IndexPage;
